// src/components/ip_addresses.js

export const ipAddresses = {
  ip_addresses: [
    {
      ip: "144.126.156.151",
      location: [38.6364, -90.1985],
      try: "US",
      code: "NA",
      timezone: "America/Chicago",
      country_name: "United States"
    },
    {
      ip: "149.102.132.149",
      location: [50.8544, -1.002],
      try: "GB",
      code: "EU",
      timezone: "Europe/London",
      country_name: "United Kingdom"
    },
    {
      ip: "154.12.240.128",
      location: [47.3066, -122.2619],
      try: "US",
      code: "NA",
      timezone: "America/Los_Angeles",
      country_name: "United States"
    },
    {
      ip: "154.26.155.11",
      location: [-33.8681, 151.1967],
      try: "AU",
      code: "OC",
      timezone: "Australia/Sydney",
      country_name: "Australia"
    },
    {
      ip: "156.67.110.101",
      location: [19.0748, 72.8856],
      try: "IN",
      code: "AS",
      timezone: "Asia/Kolkata",
      country_name: "India"
    },
    {
      ip: "158.220.87.26",
      // location: [50.8544, -1.002],
      location: [51.9190, -125.1207],
      try: "GB",
      code: "EU",
      timezone: "Europe/London",
      country_name: "United Kingdom"
    },
    {
      ip: "158.220.90.177",
      location: [50.8544, -1.002],
      try: "GB",
      code: "EU",
      timezone: "Europe/London",
      country_name: "United Kingdom"
    },
    {
      ip: "161.97.141.179",
      // location: [51.1878, 6.8607],
      "location": [30.5595, 30.9375],
      try: "DE",
      code: "EU",
      timezone: "Europe/Berlin",
      country_name: "Germany"
    },
    {
      ip: "184.174.34.63",
      location: [51.2216, 6.7897],
      try: "DE",
      code: "EU",
      timezone: "Europe/Berlin",
      country_name: "Germany"
    },
    {
      ip: "185.187.235.66",
      location: [38.6364, -90.1985],
      try: "US",
      code: "NA",
      timezone: "America/Chicago",
      country_name: "United States"
    },
    {
      ip: "185.215.164.205",
      location: [51.1878, 6.8607],
      try: "DE",
      code: "EU",
      timezone: "Europe/Berlin",
      country_name: "Germany"
    },
    {
      ip: "209.126.2.203",
      // location: [38.6364, -90.1985],
      location: [-23.5505, -46.6333],
      try: "US",
      code: "NA",
      timezone: "America/Chicago",
      country_name: "United States"
    },
    {
      ip: "62.146.170.238",
      location: [47.3066, -122.2619],
      try: "US",
      code: "NA",
      timezone: "America/Los_Angeles",
      country_name: "United States"
    },
    {
      ip: "62.169.26.11",
      location: [51.1878, 6.8607],
      try: "DE",
      code: "EU",
      timezone: "Europe/Berlin",
      country_name: "Germany"
    },
    {
      ip: "62.169.26.6",
      location: [51.1878, 6.8607],
      try: "DE",
      code: "EU",
      timezone: "Europe/Berlin",
      country_name: "Germany"
    },
    {
      ip: "62.72.42.185",
      location: [19.0748, 72.8856],
      try: "IN",
      code: "AS",
      timezone: "Asia/Kolkata",
      country_name: "India"
    },
    {
      ip: "82.180.146.250",
      location: [19.0748, 72.8856],
      try: "IN",
      code: "AS",
      timezone: "Asia/Kolkata",
      country_name: "India"
    },
    {
      ip: "82.197.68.80",
      location: [1.3552, 103.8859],
      try: "SG",
      code: "AS",
      timezone: "Asia/Singapore",
      country_name: "Singapore"
    },
    {
      ip: "84.247.191.232",
      location: [51.1878, 6.8607],
      try: "DE",
      code: "EU",
      timezone: "Europe/Berlin",
      country_name: "Germany"
    },
    {
      ip: "109.123.229.192",
      location: [35.6164, 139.7425],
      try: "JP",
      code: "AS",
      timezone: "Asia/Tokyo",
      country_name: "Japan"
    },
    {
      ip: "154.53.60.58",
      location: [38.5781, -90.4256],
      try: "US",
      code: "NA",
      timezone: "America/Chicago",
      country_name: "United States"
    },
    {
      ip: "194.238.27.30",
      location: [38.6364, -90.1985],
      try: "US",
      code: "NA",
      timezone: "America/Chicago",
      country_name: "United States"
    },  
      {
      ip: "207.244.242.25",
      location: [38.6364, -90.1985],
      try: "US",
      code: "NA",
      timezone: "America/Chicago",
      country_name: "United States"
    },
    {
      ip: "212.47.70.86",
      location: [38.6364, -90.1985],
      try: "DE",
      code: "EU",
      timezone: "Europe/Berlin",
      country_name: "Germany"
    },
    {
      ip: "38.242.129.86",
      location: [51.2216, 6.7897],
      try: "DE",
      code: "EU",
      timezone: "Europe/Berlin",
      country_name: "Germany"
    },
    {
      ip: "92.118.59.172",
      location: [51.2216, 6.7897],
      try: "US",
      code: "NA",
      timezone: "America/Chicago",
      country_name: "United States"
    },
    {
      ip: "156.67.105.126",
      // location: [19.0748, 72.8856],
      location: [13.0444, 100.2357],
      try: "IN",
      code: "AS",
      timezone: "Asia/Kolkata",
      country_name: "India"
    },
    {
      ip: "81.0.221.100",
      location: [50.8544, -1.002],
      // location: [13.0444, 100.2357],

      try: "GB",
      code: "AS",
      timezone: "Europe/London",
      country_name: "United Kingdom"
    },
    // {
    //   ip: "81.0.248.53",
    //   location: [50.8544, -1.002],
    //   // location: [15.7563, 70.5018],
    //   try: "GB",
    //   code: "EU",
    //   timezone: "Europe/London",
    //   country_name: "United Kingdom"
    // },
    {
      ip: "81.0.248.53",
      location: [13.0444, 100.2357],
      try: "TH",
      code: "AS",
      timezone: "Asia/Bangkok",
      country_name: "Thailand"
    },
    {
      ip: "94.72.112.196",
      location: [38.6364, -90.1985],
      try: "US",
      code: "NA",
      timezone: "America/Chicago",
      country_name: "United States"
    },
    {
      ip: "149.102.133.8",
      location: [51.2216, 6.7897],
      try: "DE",
      code: "EU",
      timezone: "Europe/Berlin",
      country_name: "Germany"
    },
    {
      ip: "185.250.38.234",
      location: [1.3552, 103.8859],
      try: "SG",
      code: "AS",
      timezone: "Asia/Singapore",
      country_name: "Singapore"
    },
    {
      ip: "212.28.186.142",
      location: [38.6364, -90.1985],
      try: "US",
      code: "NA",
      timezone: "America/Chicago",
      country_name: "United States"
    },
    {
      ip: "38.242.216.73",
      location: [51.2216, 6.7897],
      try: "DE",
      code: "EU",
      timezone: "Europe/Berlin",
      country_name: "Germany"
    }
    // {
    //   "ip": "171.96.191.1",
    //   "location": [13.7563, 100.5018],
    //   "try": "TH",
    //   "code": "AS",
    //   "timezone": "Asia/Bangkok",
    //   "country_name": "Thailand"
    // }
  ]
};